<template>
    <div>
        <v-toolbar dense class="tc-title">
            <v-toolbar-title>Copy Offering Questions</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-card>
            <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
                <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
                <v-btn dark text @click="snackbar = false">Close</v-btn>
            </v-snackbar>
            <v-row class='pl-4'>
                <v-col md="5">
                    <label>Select Company Offering &nbsp;<small style="color:red;">*</small></label>
                    <v-autocomplete autocomplete="off" :items="offeringlist" item-text="offering" item-value="offering_id"
                        v-model="offering"></v-autocomplete>
                </v-col>
                <v-col sm="3">
                    <v-btn class="mt-6" color="primary darken-1" @click="fetch()">Fetch Questions</v-btn>
                </v-col>
            </v-row>
        </v-card>
        <br />
        <div>
            <template v-if="this.question_list.length != 0">
                <!-- <v-toolbar flat dense class="card-title">
                    <v-toolbar-title>Already Added Questions</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                </v-toolbar> -->
                <v-simple-table responsive>
                    <tr>
                        <th class="text-left">Q.No.</th>
                        <th class="text-left">Question Statement</th>
                        <th class="text-left">Question Options</th>
                        <th class="text-left">Question Options Remark</th>
                        <th class="text-left">Sort Order</th>
                        <th class="text-left">Question Type</th>
                        <th class="text-left">Remarks</th>
                        <th class="text-left">
                            <v-row>
                                Selected
                                <input v-model="selectall" class="ml-2" style="width: 20px; height: 20px" type="checkbox"
                                    @click="checkAll(question_list)" />
                            </v-row>
                        </th>
                    </tr>
                    <tbody>
                        <tr v-for="(que, index) in this.question_list" :key="index">
                            <td>
                                {{ index + 1 }}
                            </td>
                            <td>
                                {{ que.question_stmt }}
                            </td>
                            <td v-if="que.option_list != ''">
                                <span v-for="(op,index) in que.option_list" :key="index">
                                    {{ op.answer }}<span v-if="que.option_list.length-1 !=index">,</span>
                                </span>
                            </td>
                            <td v-else>
                                NA
                            </td>
                            <td v-if="que.option_list != ''">
                                <span v-for="(op,index) in que.option_list" :key="index">
                                    {{ op.option_remark }}<span v-if="que.option_list.length-1 !=index">,</span>
                                </span>
                            </td>
                            <td v-else>
                                NA
                            </td>
                            <td>
                                {{ que.sort_order }}
                            </td>
                            <td>
                                {{ que.question_type }}
                            </td>
                            <td>
                                {{ que.remark }}
                            </td>
                            <td>
                                <input style="height: 20px; width: 20px" type="checkbox" v-model="que.isselected" />
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </template>

            <v-row v-if="this.question_list.length != 0" class='pl-4'>
                <v-col md="5">
                    <label>Select Company Offering To COPY Selected Question&nbsp;<small
                            style="color:red;">*</small></label>
                    <v-autocomplete autocomplete="off" :items="offeringlist" item-text="offering" item-value="offering_id"
                        v-model="offeringnew"></v-autocomplete>
                </v-col>
                <v-col sm="3">
                    <v-btn class="mt-6" color="primary darken-1" @click="copyquestions()">Copy Questions</v-btn>
                </v-col>
            </v-row>


        </div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>
<script>
import axios from "axios";
import { table2excel } from "../jquery.table2excel";
import readXlsxFile from "read-excel-file";
export default {
    data: () => ({
        overlay: false,
        snackbar_msg: "",
        color: "",
        snackbar: false,
        search: "",
        selectall: false,
        isselected: false,
        offeringlist: [],
        offering: "",
        question_list: [],
        offeringnew: "",

    }),

    mounted() {
        axios
            .post("/TPO/getcompanyoffering_tocopyquestion")
            .then(res => {
                if (res.data.msg == "200") {
                    this.offeringlist = res.data.offeringlist;
                } else {
                    //console.log("error fetching data!");
                }
            });
    },

    methods: {
        exportexcel() {
            $("#exceltable").table2excel({
                name: "Worksheet Name",
                filename: "Student_reg_Dashboard", //do not include extension
                fileext: ".xls" // file extension
            });
        },
        checkAll(hello) {
            //console.log(this.selectall)
            for (var i = 0; i < this.question_list.length; i++) {
                if (!this.selectall) {
                    //console.log("true")
                    //console.log(this.question_list[i].isselected)
                    this.question_list[i].isselected = true;
                } else {
                    //  console.log("false");
                    this.question_list[i].isselected = false;
                }
            }
        },
        fetch() {
            if (this.offering == null || this.offering == "") {
                this.showSnackbar("red", "Please Select Company Offering");
                return;
            }
            //  alert("hiii")
            //console.log(this.offering)
            this.overlay = true;
            const data = {
                offering: this.offering,
            };
            axios
                .post("TPO/getquestionbyoffering", data)
                .then((res) => {
                    this.overlay = false;
                    if (res.data.msg == "200") {
                        this.question_list = res.data.question_list;
                        //console.log(" list----------");
                        //console.log(this.question_list)
                        if (this.question_list.length < 1) {
                            this.showSnackbar("red", "Data Not Found");
                        }
                    } else {
                        this.showSnackbar("red", res.data.error);
                    }
                }).catch((error) => {
                    this.overlay = false;
                    window.console.log(error);
                }).finally(() => {
                    this.overlay = false;
                });
        },
        copyquestions() {
            // alert("hiii")
            //console.log(this.offering)
            if(!this.offeringnew){
                this.showSnackbar("red", "Please select company offering to copy selected question.");
                return;
            }

            this.overlay = true;
            const data = {
                offeringnew: this.offeringnew,
                question_list: this.question_list,
            };
            axios
                .post("TPO/copyquestions", data)
                .then((res) => {
                    this.overlay = false;
                    if (res.data.code == "200") {
                        this.showSnackbar("green", "Questions Copied Successfully");
                    } else {
                        this.showSnackbar("red", res.data.error);
                    }
                }).catch((error) => {
                    this.overlay = false;
                    window.console.log(error);
                }).finally(() => {
                    this.overlay = false;
                });
        },

        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },

        clear() {
            this.excelfile = null;
            this.exceldialog = false;
        },

    }
};
</script>
<style scoped>
.required {
    color: red;
    font-size: 14px;
    font-style: bold;
}

.tc-title {
    background-image: linear-gradient(-90deg, #fff, #057996);
    color: #fff;
    border-radius: 3px;
}
</style>    